import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { formatNumberWithout, groupByCategoryName } from "../../helper";

import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { home } from "./fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faMinus,
  faPlus,
  faShoppingBag,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { BalanceContext } from "../BalanceContext";

const Home = () => {
  const { setBalance } = useContext(BalanceContext);

  const [qyt, setQyt] = useState(0);
  const [qytVar, setQytVar] = useState(1);
  const [priceVar, setPriceVar] = useState(0);
  const [merchant_id, setMerchId] = useState(0);
  const [show, setShow] = useState(false);
  const [menus, setMenus] = useState([]);
  const [menuVariation, setMenuVariation] = useState([]);
  const [variation, setVariation] = useState([]);
  const [menuVar, setMenuVar] = useState([]);
  const navigate = useNavigate();

  const qytAdd = (items, qyt2, type, e) => {
    if (items.variation.length > 0 && type === "menu") {
      setShow(true);
      setMenuVar(items);
      setVariation(groupByCategoryName(items.variation));
    } else {
      e.preventDefault();
      const qytMenus = menus.find(
        (menuItem) => menuItem.id === items.id && menuItem.qyt >= 1
      );
      if (qytMenus) {
        const updatedData = menus.map((item) => {
          if (item.id === items.id) {
            const menu_variation =
              menuVariation.length > 0 &&
              addMenuVariation(item.menu_variation, {
                variation: menuVariation,
                price: getTotalPrice(),
                qyt: qytVar,
              });
            return {
              ...item,
              qyt: item.qyt + qyt2,
              menu_variation,
            };
          }
          return item;
        });
        setQyt(qyt + qyt2);
        setMenus(updatedData);
        setQytVar(0);
        setShow(false);
        setMenuVariation([]);
      } else {
        const updatedData = menus.map((item) => {
          const menu_variation =
            menuVariation.length > 0 &&
            addMenuVariation(item.menu_variation, {
              variation: menuVariation,
              price: getTotalPrice(),
              qyt: qytVar,
            });
          if (item.id === items.id) {
            return {
              ...item,
              qyt: qyt2,
              menu_variation,
            };
          }
          return item;
        });
        setQyt(qyt + qyt2);
        setMenus(updatedData);
        setQytVar(0);
        setShow(false);
        setMenuVariation([]);
      }
    }
  };

  const addMenuVariation = (existingVariations, newVariation) => {
    if (!existingVariations || existingVariations.length === 0) {
      return [newVariation];
    }

    const exists = existingVariations.some(
      (variation) =>
        variation.menuVariation === newVariation.menuVariation &&
        variation.price === newVariation.price
    );

    if (exists) {
      return existingVariations.map((variation) =>
        variation.menuVariation === newVariation.menuVariation
          ? { ...variation, ...newVariation }
          : variation
      );
    } else {
      return [...existingVariations, newVariation];
    }
  };

  const qytSub = (items, qyt2) => {
    const updatedData = menus.map((item) => {
      if (item.id === items.id && item.qyt >= 1) {
        setQyt(qyt - qyt2);
        return { ...item, qyt: item.qyt - qyt2 };
      }
      return item;
    });
    setMenus(updatedData);
  };

  const qytRemove = (items, qyt2) => {
    const updatedData = menus.map((item) => {
      if (item.id === items.id && item.qyt >= 1) {
        setQyt(qyt - items.qyt);
        return { ...item, qyt: 0, menu_variation: false };
      }
      return item;
    });
    setMenus(updatedData);
  };

  const qytAddVar = () => {
    setPriceVar(priceVar * (qytVar + 1));
    setQytVar(qytVar + 1);
  };

  const qytSubVar = () => {
    if (qytVar <= 0) {
    } else {
      setQytVar(qytVar - 1);
    }
  };

  const handlePayNow = () => {
    const menu = menus.filter((menuItem) => menuItem.qyt >= 1);
    const resMenu = menu.map((item) => {
      const menu_variation =
        item.menu_variation !== false ? item.menu_variation : [];
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        image: item.image,
        price: item.price,
        qyt: item.qyt,
        menu_variation,
      };
    });
    const listMenu = menu.map((item) => {
      const menu_variation =
        item.menu_variation !== false ? item.menu_variation : [];
      return {
        id: item.id,
        price: item.price,
        qyt: item.qyt,
        menu_variation,
      };
    });

    const params = {
      merchant_id: merchant_id,
      price: getTotalPrice2(),
      qyt: qyt,
      menu: listMenu,
    };

    console.log(merchant_id);

    secureLocalStorage.setItem("params", JSON.stringify(params));

    navigate("/payscanner", {
      replace: false,
      state: { price: getTotalPrice2(), qyt: qyt, menus: resMenu },
    });
  };

  const getHome = useCallback(async () => {
    const param = { code: secureLocalStorage.getItem("code") };

    try {
      const res = await home(param);
      if (res.status) {
        secureLocalStorage.setItem("id", res.data.id);
        setMerchId(res.data.id);
        const balance = {
          balance: res.data.amount,
          cash: res.data.pending_cash,
        };
        setBalance(balance);
        secureLocalStorage.setItem("balance", balance);
        setMenus(res.data.menu);
      }
    } catch (error) {}
  }, [setBalance]);

  const handleVariation = (array, menu_id, var_id, code, price) => {
    const newItem = array
      .filter(
        (item) =>
          item.variant_code === code &&
          item.id === var_id &&
          item.menu_id === menu_id
      )
      .map((item) => ({
        variation_code: item.variant_code,
        id: var_id,
        price: price,
        name: item.name,
        category_name: item.category_name,
      }))[0];

    const existsIndex = menuVariation.findIndex(
      (item) => item.variation_code === newItem.variation_code
    );

    if (existsIndex !== -1) {
      const updatedMenuVariation = [...menuVariation];
      updatedMenuVariation[existsIndex] = newItem;
      setMenuVariation(updatedMenuVariation);
    } else {
      setMenuVariation([...menuVariation, newItem]);
    }
  };

  const getTotalPrice = () => {
    return menuVariation.reduce((total, item) => total + item.price, 0);
  };

  const getTotalPrice2 = () => {
    return menus.reduce((total, menu) => {
      // Tambahkan harga menu utama jika qyt ada dan > 0
      let menuPrice = 0;
      if (menu.qyt && menu.qyt > 0) {
        menuPrice = menu.price * menu.qyt;
      }

      // Jika menu_variation adalah array, tambahkan harga variasi jika qyt ada dan > 0
      if (Array.isArray(menu.menu_variation)) {
        menu.menu_variation.forEach((variation) => {
          if (variation.qyt && variation.qyt > 0) {
            menuPrice += variation.price * variation.qyt;
          }
        });
      }

      return total + menuPrice;
    }, 0);
  };

  useEffect(() => {
    getHome();
  }, [getHome]);

  return (
    <Container className="home" fluid>
      <Modal show={show} onHide={() => setShow(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Pilih Variasi Menu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={require("../../assets/no-image-found.png")}
            className="w-100 h-25 object-fit-center mb-5"
          />
          <Form
            className="pb-5"
            onSubmit={(e) => qytAdd(menuVar, qytVar, "variant", e)}
          >
            {variation
              ? variation.map((items, index) => {
                  return (
                    <Row key={index} className="mb-4">
                      <Col xs="12">
                        <h5 style={{ fontWeight: "600", color: "black" }}>
                          {items.category_name}
                        </h5>
                        {items.variations.map((item, index) => {
                          return (
                            <Row key={index}>
                              <Col xs={6}>
                                <Form.Check
                                  type="radio"
                                  onChange={(e) =>
                                    handleVariation(
                                      items.variations,
                                      item.menu_id,
                                      item.id,
                                      item.variant_code,
                                      item.price
                                    )
                                  }
                                  label={item.name}
                                  name={item.variant_code}
                                  value={item.variant_code}
                                  required={
                                    item.required === "1" ? true : false
                                  }
                                />
                              </Col>
                              <Col xs={6} className="text-end">
                                <Form.Label>
                                  <b>
                                    {item.price !== 0
                                      ? `Rp ${formatNumberWithout(item.price)}`
                                      : "Rp 0"}
                                  </b>
                                </Form.Label>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                  );
                })
              : ""}
            <Row
              style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                paddingTop: "10px",
                height: 80,
                backgroundColor: "white",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Col xs="5">
                <Row>
                  <Col
                    className="text-center qyt-variant"
                    onClick={() => qytSubVar(1)}
                  >
                    -
                  </Col>
                  <Col className="text-center qyt-variant">{qytVar}</Col>
                  <Col
                    className="text-center qyt-variant"
                    onClick={() => qytAddVar(1)}
                  >
                    +
                  </Col>
                </Row>
              </Col>
              <Col xs="7">
                <Button
                  className="w-100"
                  type="submit"
                  style={{
                    backgroundColor: "#24b0a2",
                    fontWeight: "600",
                    border: "1px solid #24b0a2",
                  }}
                >
                  Tambah - Rp {formatNumberWithout(getTotalPrice() * qytVar)}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Col className="menu">
        <Col className="mb-2">
          <Form.Control type="text" placeholder="Cari Menu.." />
        </Col>
        <Row>
          {menus
            ? menus.map((item, index) => {
                return (
                  <Col xs={6} className="col-menu" key={index}>
                    <Col
                      className="box-menu"
                      style={
                        item.qyt >= 1 ? { borderLeft: "2px solid #24b0a2" } : {}
                      }
                    >
                      <Col xs={12}>
                        <Image
                          src={require("../../assets/no-image-found.png")}
                          className="img-menu"
                        />
                      </Col>
                      <Col xs={12} className="menu-title">
                        <h5
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: "rgba(0, 0, 0, 1)",
                          }}
                        >
                          {item.name}
                        </h5>
                        <h6
                          className="mb-2"
                          style={{
                            color: "#959595",
                            fontSize: ".7rem",
                            fontWeight: 400,
                          }}
                        >
                          {item.description}
                        </h6>
                        <h5
                          className="mb-4"
                          style={{ fontWeight: 600, fontSize: "1rem" }}
                        >
                          Rp. {formatNumberWithout(item.price)}
                        </h5>
                        <Row
                          className="tot-menu"
                          style={
                            item.qyt >= 1
                              ? {
                                  border: "1px solid #24b0a2",
                                  borderRadius: "30px",
                                }
                              : {
                                  border: "none",
                                  backgroundColor: "#24b0a2",
                                  borderRadius: "30px",
                                }
                          }
                        >
                          {item.qyt >= 1 && item.menu_variation === false && (
                            <>
                              <Col
                                className="sub-menu"
                                xs={3}
                                onClick={() => qytSub(item, 1, "menu")}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </Col>
                              <Col className="qyt-menu" xs={6}>
                                {item.qyt ?? 0}
                              </Col>
                            </>
                          )}

                          {item.qyt >= 1 && item.menu_variation !== false && (
                            <>
                              <Col
                                className="sub-menu"
                                xs={3}
                                onClick={() => qytRemove(item, 1, "menu")}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Col>
                              <Col className="qyt-menu" xs={6}>
                                {item.qyt ?? 0}
                              </Col>
                            </>
                          )}
                          <Col
                            className={`add-menu ${
                              item.qyt >= 1 ? "w-25" : "w-100"
                            }`}
                            style={
                              item.qyt >= 1
                                ? {
                                    color: "#24b0a2",
                                  }
                                : { color: "white" }
                            }
                            xs={3}
                            onClick={(e) => qytAdd(item, 1, "menu", e)}
                          >
                            {item.qyt >= 1 ? (
                              <FontAwesomeIcon icon={faPlus} />
                            ) : (
                              "Add"
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Col>
                );
              })
            : ""}
        </Row>
      </Col>
      {qyt !== 0 && (
        <Row className="cart-menu" onClick={() => handlePayNow()}>
          <Col
            xs={7}
            style={{ color: "white", fontWeight: 600, textAlign: "start" }}
          >
            <FontAwesomeIcon
              icon={faShoppingBag}
              style={{ marginRight: "10px" }}
            />{" "}
            Rp.
            {formatNumberWithout(getTotalPrice2())} • {qyt} Item
          </Col>
          <Col
            xs={5}
            style={{ fontWeight: 600, color: "white", textAlign: "end" }}
          >
            Bayar{" "}
            <FontAwesomeIcon
              icon={faArrowRightLong}
              style={{ marginLeft: "10px" }}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Home;
