import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import QrScanner from "react-qr-scanner";
import { useLocation } from "react-router-dom";
import { formatCardInput, formatNumberWithout } from "../../helper";
import { transaction, paymentMethod } from "./fetch";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faClose,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";

const PayScanner = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [listPayment, setListPayment] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [cameraPos, gantiCemara] = useState("environment");
  const [showPending, setShowPending] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [isCameraActive, setIsCameraActive] = useState(false);
  const menus = location?.state?.menus;

  const handleScan = (data) => {
    if (data) {
      setCardNumber(data.text);
      setIsCameraActive(false);
    }
  };
  const handlePayment = async (data) => {
    if (secureLocalStorage.getItem("params")) {
      if (data) {
        setShowPending(true);
        try {
          if (selectedOption.type === "scan-qr" && cardNumber === null) {
            alert("Nomor Kartu Kosong");
            setShowPending(false);
          } else {
            const params = JSON.parse(secureLocalStorage.getItem("params"));
            params.payment_method = selectedOption.type;
            params.unique_code = cardNumber;
            params.discount = handlePriceDiscount();

            const res = await transaction(params);
            if (res.status) {
              setShowPending(false);
              setTimeout(() => {
                navigate("/", { replace: false });
                alert("Pembayaran Berhasil");
              }, 500);
            } else {
              alert(res.message);
              setShowPending(false);
            }
          }
        } catch (e) {
          setShowPending(false);
        }
      }
    } else {
      setShowPending(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  // const constraints = {
  //   video: {
  //     facingMode: cameraPos,
  //     height: "5rem",
  //   },
  // };

  const handlePaymentMethod = async () => {
    try {
      const res = await paymentMethod({ type: "merchant" });
      if (res.status) {
        setListPayment(res.data);
        setSelectedOption(res.data[0]);
      } else {
        alert("Gagal Mendapatkan Metode Pembayaran");
      }
    } catch (error) {
      console.error(error);
      alert("Gagal Mendapatkan Metode Pembayaran");
    }
  };

  const handlePriceDiscount = () => {
    const discountType = selectedOption.discount_type;
    const discount = selectedOption.discount;
    const max_discount = selectedOption.max_discount;
    const price = location?.state?.price ?? 0;
    if (price > selectedOption.min_discount) {
      if (discountType === 1) {
        const disc = ((price * discount) / 100).toFixed(0);
        if (max_discount !== null) {
          if (disc >= max_discount) {
            return max_discount;
          } else {
            return disc;
          }
        } else {
          return price - disc;
        }
      } else if (discountType === 2) {
        const disc = discount;
        if (max_discount !== null) {
          if (disc >= max_discount) {
            return max_discount;
          } else {
            return disc;
          }
        } else {
          return disc;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    handlePaymentMethod();
  }, []);

  return (
    <Container className="payscanner" fluid>
      {isCameraActive ? (
        <>
          <h3 className="mb-3">Scan Kartu</h3>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            className="qr-scanner"
            constraints={{
              video: {
                facingMode: cameraPos,
              },
            }}
          />
          <Button
            className="w-100 mb-2 mt-4"
            style={{
              backgroundColor: "#24b0a2",
              border: "1px solid #24b0a2",
            }}
            onClick={() =>
              gantiCemara(cameraPos === "environment" ? "user" : "environment")
            }
          >
            <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faRotate} />
            Ganti Posisi Kamera
          </Button>
          <Button
            className="w-100"
            style={{
              backgroundColor: "#24b0a2",
              border: "1px solid #24b0a2",
            }}
            onClick={() => setIsCameraActive(false)}
          >
            <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faClose} />
            Tutup Camera
          </Button>
        </>
      ) : (
        <>
          <Modal show={showPending} centered className="p-5">
            <Col className="p-4 text-center">
              <h2>Tunggu Sebentar ..</h2>
            </Col>
          </Modal>
          <Col className="nav text-center">
            <Row className="w-100">
              <Col
                className="text-left"
                onClick={() => navigate("/", { replace: false })}
              >
                <FontAwesomeIcon icon={faArrowLeftLong} />
              </Col>
              <Col className="text-center">
                <h4>Order</h4>
              </Col>
              <Col />
            </Row>
          </Col>
          <h4>Pesanan Anda</h4>
          <Col className="p-2">
            {menus.map((item, index) => {
              return (
                <Row key={index} className="row-cart-menu">
                  <Col xs={4}>
                    <Image
                      src={require("../../assets/no-image-found.png")}
                      className="img-menu"
                    />
                  </Col>
                  <Col xs={8} className="text-start">
                    <h5
                      style={{
                        fontWeight: 500,
                        fontSize: "1rem",
                        color: "rgba(0, 0, 0, 1)",
                      }}
                    >
                      {item.name}
                    </h5>
                    <h6
                      className="mb-2"
                      style={{
                        color: "#959595",
                        fontSize: ".8rem",
                        fontWeight: 400,
                      }}
                    >
                      {item.description}
                    </h6>
                    <Row className="d-flex justify-content-between">
                      <Col
                        xs={9}
                        style={{ width: "fit-content", fontSize: "1rem" }}
                      >
                        <h6 style={{ fontWeight: 500 }}>
                          Rp. {formatNumberWithout(item.price)}
                        </h6>
                      </Col>
                      <Col xs={3} className="text-end">
                        <h6>{item.qyt}x</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
          <Row
            className="col-payment-detail"
            style={
              selectedOption.type === "scan-qr"
                ? { height: "fit-content", paddingBottom: "5rem" }
                : {}
            }
          >
            <ButtonGroup style={{ height: "3rem", marginBottom: ".7rem" }}>
              {listPayment
                ? listPayment.map((item, index) => {
                    return (
                      <Button
                        className="w-100"
                        key={index}
                        onClick={() => setSelectedOption(item)}
                        style={
                          selectedOption.type === item.type
                            ? {
                                backgroundColor: "#24b0a2",
                                border: "1px solid #24b0a2",
                                color: "white",
                              }
                            : {
                                backgroundColor: "white",
                                border: "1px solid #24b0a2",
                                color: "#24b0a2",
                              }
                        }
                      >
                        {item.name}
                      </Button>
                    );
                  })
                : ""}
            </ButtonGroup>
            <Col className="col-payment-detail-list">
              <h4
                style={{
                  color: "black",
                  fontSize: "1.2rem",
                  marginBottom: ".9rem",
                  fontWeight: 400,
                }}
              >
                Ringkasan Pembayaran
              </h4>
              <Col className="d-flex justify-content-between">
                <h6
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  Subtotal
                </h6>
                <h6
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  Rp{formatNumberWithout(location?.state?.price ?? 0)}
                </h6>
              </Col>
              <Col className="d-flex justify-content-between">
                <h6
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  Potongan
                </h6>
                <h6
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  Rp{formatNumberWithout(handlePriceDiscount())}
                </h6>
              </Col>
              <hr className="dashed-hr" />
              <Col className="d-flex justify-content-between">
                <h5>Total Pembayaran</h5>
                <h6
                  style={{
                    color: "black",
                    fontSize: "1rem",
                    fontWeight: 500,
                    marginBottom: "15px",
                  }}
                >
                  Rp
                  {formatNumberWithout(
                    (location?.state?.price ?? 0) - handlePriceDiscount()
                  )}
                </h6>
              </Col>
              <Row className="p-2">
                <Col xs={6}></Col>
                <Col xs={6}></Col>
              </Row>
            </Col>
            {selectedOption.type === "scan-qr" ? (
              <div className="px-3 text-center w-100 flex-column">
                <Button
                  className="mb-4 w-100"
                  style={{ backgroundColor: "#24b0a2", border: "none" }}
                  onClick={() => setIsCameraActive(!isCameraActive)}
                >
                  {isCameraActive ? "Masukan Nomor Kartu" : "Scan Kartu"}
                </Button>
                <Form.Control
                  placeholder="Masukkan Nomor Kartu"
                  className="px-4 text-center"
                  onChange={(e) =>
                    setCardNumber(formatCardInput(e.target.value))
                  }
                  value={cardNumber}
                />
                <Button className="btn-paynow" onClick={handlePayment}>
                  Pay Now
                </Button>
              </div>
            ) : (
              selectedOption.type !== undefined && (
                <Button className="btn-paynow" onClick={handlePayment}>
                  Pay Now
                </Button>
              )
            )}
          </Row>{" "}
        </>
      )}
    </Container>
  );
};

export default PayScanner;
